import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import emojis from "@/common/constants/menstrual-symptoms-emoji";
import moods from "@/common/constants/menstruation-moods";
import MenstrualCalendarBanner from "./Banner.vue";
import Calendar from "@/common/components/Calendar";
import { mapState } from "vuex";
import { getLastMenstrualCalendarByOwner, getMenstrualCalendarById, getLastMenstrualCalendarCycleLogByOwner, getMenstrualCycleLog } from "@/common/lib/polkadot-provider/query/menstrual-calendar";
import { getActiveSubscriptionByOwner, getMenstrualSubscriptionById } from "@/common/lib/polkadot-provider/query/menstrual-subscription";
export default {
  name: "MenstrualCalendarDetail",
  data: () => ({
    emojis,
    moods,
    selectedMonthText: "",
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    selectedDates: null,
    submitEnabled: false,
    submitPreview: false,
    selectAverage: true,
    showStart: false,
    averageCycle: 0,
    monthList: [{
      value: 0,
      text: "January"
    }, {
      value: 1,
      text: "February"
    }, {
      value: 2,
      text: "March"
    }, {
      value: 3,
      text: "April"
    }, {
      value: 4,
      text: "May"
    }, {
      value: 5,
      text: "June"
    }, {
      value: 6,
      text: "July"
    }, {
      value: 7,
      text: "August"
    }, {
      value: 8,
      text: "September"
    }, {
      value: 9,
      text: "October"
    }, {
      value: 10,
      text: "November"
    }, {
      value: 11,
      text: "December"
    }],
    menstrualCalendarData: null,
    menstruationPeriodeIndex: [],
    todaySum: null,
    days: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    descriptions: ["Today", "Menstruation", "Prediction", "Fertility", "Ovulation"],
    durationSubscription: {
      Monthly: 30 * 24 * 60 * 60 * 1000,
      Quarterly: 3 * 30 * 24 * 60 * 60 * 1000,
      Yearly: 12 * 30 * 24 * 60 * 60 * 1000
    },
    reminder: ""
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  watch: {
    async selectedMonthText(newMonth) {
      this.submitPreview = true;
      this.menstruationPeriodeIndex = [];
      this.selectedMonth = this.monthList.find(value => value.text === newMonth).value;
      await this.getMenstruationCalendarData();
      this.submitPreview = false;
    },
    selectedDates(newSelected) {
      this.submitEnabled = newSelected !== null && newSelected.length > 0;
    }
  },
  methods: {
    prev() {
      if (this.submitPreview) return;
      if (this.selectedMonth > 0) {
        this.selectedMonth--;
      } else {
        this.selectedMonth = 11;
        this.selectedYear--;
      }
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
    },
    next() {
      if (this.submitPreview) return;
      if (this.selectedMonth < 11) {
        this.selectedMonth++;
      } else {
        this.selectedMonth = 0;
        this.selectedYear++;
      }
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
    },
    getSummary() {
      if (this.todaySum.menstruation) return moods.MENSTRUATION(this.todaySum.days);
      if (this.todaySum.ovulation) return moods.OVULATION;
      if (this.todaySum.fertility) return moods.FERTILITY;
      return moods.NONE;
    },
    async getMenstruationCalendarData() {
      this.submitPreview = true;
      try {
        const menstrualCalendar = await getLastMenstrualCalendarByOwner(this.api, this.wallet.address);
        const data = await getMenstrualCalendarById(this.api, menstrualCalendar[0]);
        const today = new Date();
        const firstDateCurrentMonth = new Date(this.selectedYear, this.selectedMonth, 1);
        const firstDateNextMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0);
        const dayFirstDateCurrentMonth = firstDateCurrentMonth.getDay() === 0 ? 6 : firstDateCurrentMonth.getDay() - 1;
        const dayFirstDateNextMonth = firstDateNextMonth.getDay() === 0 ? 6 : firstDateNextMonth.getDay() - 1;
        const startDate = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1));
        const endDate = new Date(this.selectedYear, this.selectedMonth + 1, 6 - dayFirstDateNextMonth);
        const menstrualCalendarData = {
          addressId: data.addressId,
          averageCycle: data.averageCycle,
          cycleLog: []
        };
        let date = startDate;
        let indexDate = 0;
        this.menstruationPeriodeIndex = [];
        const menstrualCycleLogByOwner = await getLastMenstrualCalendarCycleLogByOwner(this.api, menstrualCalendar[menstrualCalendar.length - 1]);
        const cycle = [];
        for (let i = 0; i < menstrualCycleLogByOwner.length; i++) {
          const cycleLogData = await getMenstrualCycleLog(this.api, menstrualCycleLogByOwner[i]);
          cycle.push(cycleLogData);
        }
        cycle.sort((a, b) => parseInt(a.date.replaceAll(",", "")) - parseInt(b.date.replaceAll(",", "")));
        let temp = [];
        for (let i = 0; i < cycle.length; i++) {
          const date = Number(cycle[i].date.replaceAll(",", ""));
          const day = new Date(date).getDate();
          const yesterday = i !== 0 ? Number(cycle[i - 1].date.replaceAll(",", "")) : null;
          if (cycle[i].menstruation) {
            if (i === 0) {
              temp.push(cycle[i]);
            } else if (new Date(yesterday).getDate() !== day - 1) {
              temp.push(cycle[i]);
            }
          }
        }
        const lastMens = temp[temp.length - 1];
        let firstDayOfLastPeriod;
        let lastMonthPrediction = [];
        let lastMonthFertility = [];
        let lastMonthOvulation = [];

        // define cycle when first day of menstruation is in the middle - end of month

        if (lastMens) {
          firstDayOfLastPeriod = new Date(Number(lastMens.date.replaceAll(",", "")));
          for (let pointer = 0; pointer < 17; pointer++) {
            // loop up to 16 as the longest date for fertility

            // calculate prediction days (5 days)
            if (pointer < 5) {
              lastMonthPrediction.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + Number(data.averageCycle) + pointer));

              // calculate 2nd prediction
              if (pointer === 0) {
                lastMonthPrediction.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + Number(data.averageCycle) + pointer));
              } else {
                lastMonthPrediction.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + Number(data.averageCycle)));
              }

              // calculate 3rd prediction

              if (pointer === 0) {
                lastMonthPrediction.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + Number(data.averageCycle) + pointer));
              } else {
                lastMonthPrediction.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + Number(data.averageCycle)));
              }
              firstDayOfLastPeriod = new Date(Number(lastMens.date.replaceAll(",", "")));
            }
            const lastPeriod = temp.find(t => new Date(Number(t.date.replaceAll(",", ""))).getMonth() === this.selectedMonth - 1);

            // calculate fertility days (9 dayas)
            if (pointer > 8) {
              if (lastPeriod) {
                // if calculation of last cycle fertility is on current month
                lastMonthFertility.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + pointer));
                firstDayOfLastPeriod = new Date(Number(lastPeriod.date.replaceAll(",", "")));
              }
              lastMonthFertility.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + pointer));
              firstDayOfLastPeriod = new Date(Number(lastMens.date.replaceAll(",", "")));
            }

            // calculate ovulation days (3 days)
            if (pointer > 12 && pointer < 16) {
              if (lastPeriod) {
                // if calculation of last cycle fertility is on current month
                lastMonthOvulation.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + pointer));
                firstDayOfLastPeriod = new Date(Number(lastPeriod.date.replaceAll(",", "")));
              }
              lastMonthOvulation.push(firstDayOfLastPeriod.setDate(firstDayOfLastPeriod.getDate() + pointer));
              firstDayOfLastPeriod = new Date(Number(lastMens.date.replaceAll(",", "")));
            }
          }
        }

        // define cycle when first day of menstruation is in the beginning of month

        while (date.getTime() < endDate.getTime()) {
          date = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1) + indexDate);
          const log = cycle.filter(log => Number(log.date.replaceAll(",", "")) === date.getTime());
          const menstruation = log[0];
          const symptoms = menstruation ? menstruation.symptoms : [];
          if (menstruation !== null && menstruation !== void 0 && menstruation.menstruation) this.menstruationPeriodeIndex.push(indexDate);
          let currentData = {
            date: date.getTime(),
            menstruation: log.length && menstruation.menstruation ? 1 : 0,
            prediction: lastMonthPrediction.find(pred => pred === date.getTime()) || indexDate >= this.menstruationPeriodeIndex[0] + Number(data.averageCycle) && indexDate < this.menstruationPeriodeIndex[0] + Number(data.averageCycle) + 5 ? 1 : 0,
            fertility: lastMonthFertility.find(pred => pred === date.getTime()) || indexDate >= this.menstruationPeriodeIndex[0] + 8 && indexDate <= this.menstruationPeriodeIndex[0] + 16 ? 1 : 0,
            ovulation: lastMonthOvulation.find(pred => pred === date.getTime()) || indexDate >= this.menstruationPeriodeIndex[0] + 13 && indexDate <= this.menstruationPeriodeIndex[0] + 15 ? 1 : 0,
            symptoms: symptoms
          };
          menstrualCalendarData.cycleLog.push(currentData);
          if (today.getDate() === date.getDate()) {
            this.todaySum = currentData;
            this.menstruationPeriodeIndex.map((num, i) => {
              this.todaySum.index = num;
              this.todaySum.days = i;
            });
          }
          indexDate++;
        }
        this.menstrualCalendarData = menstrualCalendarData;
      } catch (err) {}
      this.submitPreview = false;
    },
    toSubscriptionSetting() {
      this.$router.push({
        name: "menstrual-calendar-subscription-setting"
      });
    },
    toMenstrualSelectionUpdate() {
      this.$router.push({
        name: "menstrual-calendar-selection-update"
      });
    },
    toMenstrualCalendarExpress() {
      this.$router.push({
        name: "menstrual-calendar-select-emoji"
      });
    },
    async getActiveSubscription() {
      const activeSubs = await getActiveSubscriptionByOwner(this.api, this.wallet.address);
      const subscriptionDetail = await getMenstrualSubscriptionById(this.api, activeSubs);

      // get current date
      let currentDate = new Date().getTime();

      // date subscription
      const date = subscriptionDetail.updatedAt === "0" ? Number(subscriptionDetail.createdAt.split(",").join("")) : Number(subscriptionDetail.updatedAt.split(",").join(""));
      const endDateSubscription = date + this.durationSubscription[subscriptionDetail.duration];
      const reminder = Math.ceil((endDateSubscription - currentDate) / (24 * 60 * 60 * 1000));
      if (reminder <= 30) {
        this.reminder = reminder === 1 ? "".concat(reminder, " Day") : "".concat(reminder, " Days");
      } else if (reminder < 365) {
        const month = Math.floor(reminder / 30);
        const days = reminder % 30;
        if (days > 0) {
          this.reminder = "".concat(month, " ").concat(month === 1 ? "Month" : "Months", " ").concat(days, " ").concat(days === 1 ? "Day" : "Days");
        } else {
          this.reminder = "".concat(month, " ").concat(month === 1 ? "Month" : "Months");
        }
      } else {
        const year = Math.floor(reminder / 365);
        this.reminder = "".concat(year, " ").concat(year === 1 ? "Year" : "Years");
      }
    }
  },
  async created() {
    const today = new Date();
    this.selectedMonthText = this.monthList[today.getMonth()].text;
    this.currentYear = today.getFullYear().toString();
    this.getActiveSubscription();
  },
  components: {
    MenstrualCalendarBanner,
    Calendar
  }
};