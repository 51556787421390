export default [{
  name: "emoji-exhausted",
  text: "Exhausted",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-happy",
  text: "Happy",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-sad",
  text: "Sad",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-unwell",
  text: "Unwell",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-depressed",
  text: "Depressed",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-angry",
  text: "Angry",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-heart-eyes",
  text: "High sex drive",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-peanut",
  text: "Protected Sex",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-egg",
  text: "No Sex",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-banana",
  text: "Unprotected Sex",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-hungry",
  text: "Hungry",
  disabled: "inactive",
  color: "#363636"
}, {
  name: "emoji-craving",
  text: "Craving",
  disabled: "inactive",
  color: "#363636"
}];